@charset "UTF-8";

/**
 *  Reset/Normalize
 * ------------------------------------------------------------------------- */
@import url('./normalize.css');
@import url('./reset.css');

input,
select,
textarea {
    border-radius: 0; /* Removes border radius applied by Safari */
}
/**
 *  Variables
 * ------------------------------------------------------------------------- */
:root {
    --colour__primary: #8DB147;
    --colour__primary--dark: #8DB147;
    --colour__secondary: #DE7AAD;
    --colour__secondary--dark: #BD5196;
    --colour__font: #636362;
    --colour__font-alt: #FFF;
    --colour__grey: #E1E1E1;
    --colour__grey--dark: #9D9C9C;
    --colour__grey--light: #E6E6E6;
    --colour__white: #FFF;
    --colour__w3c__primary: #3A591D;
    --font: neo-sans, sans-serif;
    --transistion: 400ms ease-in-out all;
}

/**
 *  Useful documents section in form
 * ------------------------------------------------------------------------- */
.useful-documents-section{
    display: block;
    /* border: 1px solid red; */
    position: absolute;
    padding: 1em;
    width: 20em;
    left: 0;
    margin-bottom: 2em;
    margin-top: -1em;
    font-size: 1.3rem;
    line-height: 1.2;
}
@media only screen and (max-width: 1100px) {
    .useful-documents-section{
        width: 30em;
        /* border-color: blue; */
        position: relative;
        left: -1em;
    }
}
.useful-documents-section ul {
    color: var(--colour__secondary--dark);
}
.useful-documents-section .useful-documents-heading {
    color: var(--colour__w3c__primary);    
    margin-bottom: .3em;
}

/**
 *  Background
 * ------------------------------------------------------------------------- */
html,
body {
    min-height: 100%;
    letter-spacing: 0.2px;
}

.background__solid {
    background: var(--colour__secondary);
}

.background__gradient {
    background: linear-gradient(var(--colour__secondary), var(--colour__secondary--dark)) no-repeat;
}

.background__grey {
    background: var(--colour__grey);
}

/*
 *  Typography
 *------------------------------------------------------------------------- */
html {
    font-size: 62.5%;
}

body {
    font-weight: 400;
    font-family: var(--font);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

.typography {
    color: var(--colour__font);
    font-size: 1.3rem;
    line-height: 1.5;
}

.typography p {
    margin-bottom: 1em;
}


.typography strong {
    font-weight: 500;
}


.typography em {
    font-style: italic;
}

.typography hr {
    display: block;
    width: 100%; height: 0;
    margin: 36px 0 24px;
    border: 0;
    border-bottom: solid 1px var(--colour__font);
    outline: 0;
}

.typography h1,
.typography .h1 {
    margin-bottom: 1em;
    color: var(--colour__font);
    font-weight: 500;
    font-size: 1.8rem;
}


@media only screen and (min-width: 600px) {
    .typography h1,
    .typography .h1 {
        font-size: 2.4rem;
    }
}

.typography h2,
.typography .h2 {
    margin-bottom: 1em;
    color: var(--colour__font);
    font-weight: 500;
    font-size: 1.5rem;
}

@media only screen and (min-width: 600px) {
    .typography h2,
    .typography .h2 {
        font-size: 1.8rem;
    }
}


.typography h3,
.typography .h3 {
    margin-bottom: 1em;
    color: var(--colour__secondary);
    font-weight: 500;
    font-size: 1.5rem;
}

@media only screen and (min-width: 600px) {
    .typography h3,
    .typography .h3 {
        font-size: 1.6rem;
    }
}


.typography ul {
    margin: 0 0 1em; 
    /* padding: 0 0 0 1.5em; */
    list-style-type: disc;
}


.typography ol {
    margin: 0 0 1em; 
    padding: 0 0 0 1.5em;
    list-style-type: decimal;
}


.typography blockquote {
    margin: 0 0 1em;
    font-style: italic;
}

.typography blockquote p {
    margin: 0;
}

.typography cite {
    display: block;
    font-weight: bold;
    font-style: normal;
}

.typography sup,
.typography sub {
    position: relative;
    top: -0.4em;
    font-size: 80%;
    vertical-align: baseline;
}


.typography sub {
    top: 0.4em;
}


.typography u {
    text-decoration: none;
}


.typography a {
    color: inherit;
    font-size: 14px;
}

.typography a:link,
.typography a:visited {
    text-decoration: underline;
 }

.typography a:hover,
.typography a:active {
    text-decoration: none;
}

.typography .center {
    text-align: center;
}

.typography ul {
    list-style-type: none;
}

.typography ul li{
    margin-bottom: 5px;
}

.referrerListStyle li::before{
    position: relative;
    left: -0.5em;
    content: "+";
    color: var(--colour__primary--dark);
    line-height: 1.5;
}

.referrerListStyle li:hover::before{
    text-decoration: none;
}

.referrerListStyle li:hover{
    color: var(--colour__primary--dark);
    text-decoration: underline;
}

/* .typography ul li:before {
    position: relative;
    left: -0.5em;
    content: "+";
    color: #b2cf7c;
    line-height: 1.5;
} */

#anotherSubmitLink {
    font-size: 14px;
    cursor: pointer;
    color: var(--colour__secondary--dark);
}

#anotherSubmitLink {
    font-weight: 500;
    text-decoration: underline;
}

#anotherSubmitLink:hover {
    font-weight: 500;
    text-decoration: none;
}

.check{
    width: 130px;
}

.checkContainer{
    text-align: center;
    margin-bottom: 40px;
    margin-top: 20px;
}

/**
 *  Container
 * ------------------------------------------------------------------------- */
.container {
    max-width: 1235px;
    width: 100%;
    margin: 0 auto;
    padding: 0 17.5px;
}

/**
 *  Header
 * ------------------------------------------------------------------------- */
.header {
    margin-bottom: 10px;
    color: var(--colour__font-alt);
    line-height: 1.1;
}

@media only screen and (min-width: 750px) {
    .header {
        display: flex;
        justify-content: flex-end;
    }
}

.header__date {
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 19px;
    display: block;
}

@media only screen and (min-width: 750px) {
    .header__date {
        width: 208px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.header__time {
    margin-left: 17px;
    display: inline-block;
}

@media only screen and (min-width: 750px) {
    .header__time {
        margin-left: auto;
    }
}

.header__user-account {
    margin-bottom: 19px;
    font-size: 12px;
}

@media only screen and (min-width: 750px) {
    .header__user-account {
        min-width: 239px;
        display: flex;
        align-items: center;
        padding: 19px 25px 21px;
        background: rgba(255, 255, 255, .25);
        margin-bottom: 0;
        transition: var(--transistion);
        border-radius: 0 0 12px 12px;
    }
}

.header__user-account:hover {
    background: rgba(255, 255, 255, .50);

}

.header__user-account-avatar {
    margin-right: 21px;
    display: none;
}

@media only screen and (min-width: 750px) {
    .header__user-account-avatar {
        display: block;
    }
}

.header__user-account-triangle {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid var(--colour__white);
    margin-left: 15px;
    margin-top: 2px;
    display: none;
}

@media only screen and (min-width: 750px) {
    .header__user-account-triangle {
        display: block;
    }
}

.header__user-username {
    font-weight: 500;
    display: inline-block;
}

/**
 *  Footer
 * ------------------------------------------------------------------------- */
.footer {
    padding: 28px 0;
    color:rgba(255, 255, 255, 0.8);
    font-size: 12px;
}

p.footer__content {
    color: var(--colour__white);
    font-size: 12px;
    margin-bottom: 0;
    text-align: center;
}

p.footer__content a {
    display: block;
    margin: 1em 0;
}

@media only screen and (min-width: 750px) {
    p.footer__content {
        text-align: left;
    }

    p.footer__content a {
        display: inline;
        margin: 0;
    }
}

.footer__delimiter {
    display: flex;
    opacity: 0;
}

@media only screen and (min-width: 750px) {
    .footer__delimiter {
        display: inline-block;
        margin: 0 20px;
        opacity: 1;
    }
}

/**
 *  Main
 * ------------------------------------------------------------------------- */
.main {
    border-radius: 12px;
    background: var(--colour__white);
    position: relative;
}

.main__top {
    padding: 4px 0 10px;
    text-align: center;
}

@media only screen and (min-width: 850px) {
    .main__top {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
}

.main__top-logo {
    margin: 10px 29px;
    width: 154px;
    display: inline-block;
}

h1.main__top-title {
    margin-bottom: 12px;
    margin-right: 20px;
    font-size: 19px;
    color: var(--colour__font);
}

.main__section {
    width: 100%;
    max-width: 704px;
    margin: 0 auto;
    padding: 24px 20px;
}

@media only screen and (min-width: 685px) {
    .main__section {
        padding: 41px 20px;
    }
}

@media only screen and (min-width: 550px) {
    .main__bottom {
        display: flex;
        border-top: 1px solid var(--colour__font);
        padding: 30px 0 10px;
    }
}

.main__bottom-column {
    text-align: center;
    font-size: 15px;
}

.main__bottom-column:first-child {
    padding-bottom: 16px;
    border-bottom: 1px solid var(--colour__font);
    margin-bottom: 16px;
}

@media only screen and (min-width: 550px) {
    .main__bottom-column {
        width: 50%;
        padding: 0 12px;
        font-size: 16px;
    }

    .main__bottom-column:first-child {
        padding-bottom: 0;
        border-bottom: none;
        margin-bottom: 0;
    }
}

/**
 *  Progress bar
 * ------------------------------------------------------------------------- */
ol.progress__stages {
    width: 100%;
    margin: 0;
    padding: 11px 0;
    display: none;
    align-items: center;
    justify-content: center;
    min-height: 42px;
    color: var(--colour__font);
}

@media only screen and (min-width: 685px) {
    ol.progress__stages {
        display: flex;
        font-size: 1.3rem;
        list-style: decimal;
    }
}

.progress__stage {
    margin: 0 18px;
}

.progress__stage--active {
    color: var(--colour__font);
    /* color: #3A591D; */
    font-weight: 500;
}

.progress__bar {
    background: var(--colour__font);
    /* background: #3A591D; */
    height: 4px;
    display: none;
}

@media only screen and (min-width: 685px) {
    .progress__bar {
        display: block;
    }
}

.progress__bar--20 {
    width: 20%;
}

.progress__bar--40 {
    width: 40%;
}

.progress__bar--60 {
    width: 60%;
}

.progress__bar--80 {
    width: 80%;
}

.progress__bar--100 {
    width: 100%;
}

.progress__bar-step {
    font-size: 14px;
    text-align: center;
    background-color: var(--colour__primary);
    color: var(--colour__white);
    padding: 9px 0 7px;
    margin-bottom: 0 !important;
}

.progress__bar-step.progress__bar-step--final {
    background-color: var(--colour__grey--dark);
}

@media only screen and (min-width: 685px) {
    .progress__bar-step {
        display: none;
    }
}

/**
 *  Appointment types
 * ------------------------------------------------------------------------- */
.appointment-types {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
}

@media only screen and (min-width: 460px) {
    .appointment-types {
        padding: 16px 0;
        display: block;
        margin-bottom: 0;
    }
}

.appointment-types__type {
    width: 100%;
    max-width: calc(50% - 5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 13px 5px;
    text-align: center;
    margin-bottom: 10px;
    background: var(--colour__grey);
    cursor: pointer;
}

@media only screen and (min-width: 460px) {
    .appointment-types__type {
        max-width: none;
        margin-bottom: 14px;
        flex-direction: row;
        padding: 18px 46px 18px 19px;
        text-align: left;
    }
}

.appointment-types__type-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.appointment-types__type-image-wrapper {
    width: 58px; height: 58px;
    background: var(--colour__white);
    overflow: hidden;
    border-radius: 50%;
    flex-shrink: 0;
    position: relative;
    margin-bottom: 16px;
}

@media only screen and (min-width: 460px) {
    .appointment-types__type-image-wrapper {
        margin-right: 28px;
        margin-bottom: 0;
    }
}

.appointment-types__type-svg-fill {
    fill: var(--colour__primary);
}

.appointment-types__type-svg-stroke {
    stroke: var(--colour__primary);
}

.appointment-types__type-content {
    margin-right: auto;
}

.appointment-types__type-content-title,
.appointment-types__type-content-text {
    display: block;
}

.appointment-types__type-content-title {
    margin-bottom: 6px !important;
}

.appointment-types__type-content-title.appointment-types__type-content-title--alt {
    color: var(--colour__secondary);
}

.appointment-types__type-checkbox {
    display: none;
}

@media only screen and (min-width: 460px) {
    .appointment-types__type-checkbox {
        width: 40px;
        height: 40px;
        display: block;
        opacity: 0;
        background-color: var(--colour__primary);
        margin: auto 0 auto 24px;
        position: relative;
        transition: var(--transistion);
        flex-shrink: 0;
    }
}

.appointment-types__type-checked,
.appointment-types__type-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

@media only screen and (min-width: 460px) {
    .appointment-types__type-input:checked ~ .appointment-types__type-checkbox {
        opacity: 1;
    }
}

/**
 *  Button Holder
 * ------------------------------------------------------------------------- */
.button-holder {
    text-align: center;
    padding-bottom: 10px;
}

.button-holder__go-back {
    display: block;
    margin-bottom: 23px;
}

.button-holder__go-back.button-holder__go-back--alt {
    color: var(--colour__secondary);
}

/**
 *  Button
 * ------------------------------------------------------------------------- */
button {
    display: inline-block;
    padding: 11px 24px;
    margin: 0 auto 8px;
    border: 0;
    color: var(--colour__white) !important;
    /* background-color: var(--colour__primary); */
    background-color: #3A591D;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none !important;
    text-align: center;
    vertical-align: center;
    line-height: 1;
    transition: var(--transistion);
    cursor: pointer;
}

button:hover {
    opacity: 0.65;
}

.button--extra-wide {
    max-width: 324px;
    width: 100%;
}

.button--inline {
    margin: 0 8px 12px;
    padding: 11px 36px;
}

@media only screen and (min-width: 675px) {
    .button--inline {
        margin: 0 8px 24px;
    }
}

.button--postcode {
    font-size: 15px;
    height: 30px;
    width: 100%;
    padding: 6px 24px;
    margin: 10px auto 0;
}

.cancelLink{
    color: var(--colour__secondary);
    margin: 1em;
    text-decoration: underline;
    display: block;
    margin-bottom: 23px;
}

.cancelLink:visited {
    color: var(--colour__secondary);
    text-decoration: underline;
    display: block;
    margin-bottom: 23px;
}

@media only screen and (min-width: 675px) {
    .button--postcode {
        max-width: 191px;
        margin: 0 auto 0 27px;
    }
}

/**
 *  Modal
 * ------------------------------------------------------------------------- */
.modal__outer {
    width: 100%; height: 100%;
    position: absolute;
    top: 0; left: 0;
    padding: 10px;
    background-color: rgba(255, 255, 255, .5);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal__inner {
    max-width: 512px;
    width: 100%;
    border: 3px solid var(--colour__primary);
    background-color: var(--colour__white);
    padding: 33px 38px 0;
    text-align: center;
}

@media only screen and (min-width: 600px) {
    .modal__inner {
        margin-top: 165px;
    }
}

.modal__info {
    height: 16px; width: 16px;
    display: inline-block;
    color: var(--colour__secondary) !important;
    border: 1px solid var(--colour__grey--dark);
    text-align: center;
    font-size: 10px !important;
    text-decoration: none !important;
    border-radius: 50%;
    margin-left: 2px;
}

/**
 *  Injury areas
 * ------------------------------------------------------------------------- */
.injury-areas__options {
    display: flex;
    justify-content: center;
    padding: 16px 0 39px;

}

.injury-areas__option {
    margin: 0 13px;
}

.injury-areas__option-content {
    width: 60px; height: 60px;
    background-color: var(--colour__grey);
    color: var(--colour__secondary);
    font-size: 41px;
    cursor: pointer;
    display: block;
    overflow: hidden;
    transition: var(--transistion);
}

.injury-areas__option-input:checked ~ .injury-areas__option-content {
    background-color: var(--colour__primary);
    color: var(--colour__white);
    border-radius: 50%;
}

.injury-areas__option-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.injury-areas__dropdowns {
    padding: 24px 0 10px;
}

.injury-areas__dropdown-holder {
    max-width: 324px;
    width: 100%;
    display: flex;
    margin: 0 auto 20px;
}

.injury-areas__dropdown-label {
    width: 30px; height: 30px;
    background-color: var(--colour__grey);
    color: var(--colour__secondary);
    font-size: 24px;
    line-height: 30px;
    margin-right: 11px;
}

/**
 *  Time select
 * ------------------------------------------------------------------------- */
.time-select__top-bar {
    background-color: var(--colour__grey);
    padding: 15px 23px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 34px;
}

.time-select__filter-label {
    font-weight: 500;
    margin-right: 13px;
}

.time-select__button {
    height: 30px;
    display: inline-block;
    padding: 0 11px;
    margin: 0 5px 0;
    border: 1px solid var(--colour__font);
    background: transparent;
    color: inherit;
    font-weight: 500;
    text-decoration: none !important;
    text-align: center;
    vertical-align: center;
    line-height: 30px;
    transition: var(--transistion);
    cursor: pointer;
}

.time-select__button.time-select__button--desktop {
    display: none;
}

@media only screen and (min-width: 550px) {
    .time-select__button.time-select__button--desktop {
        display: inline-block;
    }
}

.time-select__button--wider {
    padding: 0 23px;
}

.time-select__button--larger-font {
    font-size: 18px;
}

.time-select__button--active,
.time-select__button:hover {
    border: 1px solid var(--colour__secondary--dark);
    background: var(--colour__secondary--dark);
    color: var(--colour__white);
}

.time-select__dropdown {
    display: inline-block;
    border: 1px solid var(--colour__font);
    width: 100px !important;
    text-align: left;
}

@media only screen and (min-width: 550px) {
    .time-select__dropdown {
        display: none;
    }
}

.time-select__dates {
    width: 100%;
    max-width: 586px;
    margin: 0 auto 49px;
    display: flex;
}

.time-select__date {
    width: calc(20% - 20px);
    margin: 0 10px;
}


h3.time-select__date-title {
    color: var(--colour__font);
    text-align: center;
    font-size: 13px;
    margin-bottom: 19px;
    line-height: 1.3;
}

.time-select__date-day {
    display: block;
}

@media only screen and (min-width: 650px) {
    .time-select__date-day {
        display: inline;
    }
}

.time-select__time {
    display: block;
    margin-bottom: 15px;
    cursor: pointer;
}

.time-select__time-meridiem {
    display: block;
}

@media only screen and (min-width: 650px) {
    .time-select__time-meridiem {
        display: inline;
    }
}

.time-select__time-content {
    display: block;
    text-align: center;
    border: 1px solid var(--colour__secondary--dark);
    color: var(--colour__secondary--dark);
    font-weight: 500;
    padding: 8px 0;
    transition: var(--transistion);
    line-height: 1.1;
}

@media only screen and (min-width: 650px) {
    .time-select__time-content {
        padding: 14px 0;
    }
}

.time-select__time-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.time-select__time-input:checked ~ .time-select__time-content,
.time-select__time:hover .time-select__time-content {
    background-color: var(--colour__secondary--dark);
    color: var(--colour__white);
}




/**
 *  Form
 * ------------------------------------------------------------------------- */
.form {
    max-width: 598px;
    margin: 0 auto;
    text-align: center;
}

@media only screen and (min-width: 675px) {
    .form {
        text-align: left;
    }
}

.form__bottom {
    margin-bottom: 40px;
}

.form__field {
    margin-bottom: 16px;
}

@media only screen and (min-width: 675px) {
    .form__field {
        display: flex;
        align-items: center;
    }
}

.form__date-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 675px) {
    .form__date-wrapper {
        justify-content: flex-start;
    }
}

.form__label {
    font-weight: 500;
    flex-shrink: 0;
    display: block;
    margin-right: 12px;
    margin-bottom: 7px;
    margin-left: 8px;
}

.form__label > span {
    color: #AE0F35 !important;
    font-size: 18px;
}

legend > span {
    color: #AE0F35 !important;
    font-size: 18px;
}

@media only screen and (min-width: 675px) {
    .form__label {
        width: 146px;
        margin-bottom: unset;
        margin-left: 16px;
    }
}

.form__label.form__label--auto-width {
    width: auto;
}

.form__label.form__label--margin-x {
    margin-right: 12px;
    margin-left: 12px;
}


.form__input,
.form__dropdown,
.form__textarea {
    height: 30px;
    color: var(--colour__font);
    background: var(--colour__grey);
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    width: 100%;
    padding-left: 11px;
    padding-right: 11px;
}

.form__dropdown {
    background: transparent;
    position: relative;
    z-index: 2;
    text-align: left;
}

.form__dropdown::-ms-expand {
    display: none;
}

.form__dropdown-wrapper {
    background: var(--colour__grey);
    width: 100%;
    position: relative;
}

@media only screen and (min-width: 675px) {
    .form__dropdown-wrapper.form__dropdown-wrapper--width-100 {
        width: 100px;
    }

    .form__dropdown-wrapper.form__dropdown-wrapper--width-280 {
        width: 280px;
    }
}

.form__dropdown-wrapper:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 9px solid var(--colour__secondary);
    position: absolute;
    top: 50%; right: 11px;
    transform: translateY(-50%);
}

.form__input.form__input--2-chars {
    width: 40px;
    text-align: center;
}

.form__input.form__input--4-chars {
    width: 66px;
    text-align: center;
}

@media only screen and (min-width: 675px) {
    .form__input.form__input--2-chars {
        margin-right: 22px;
    }
}

@media only screen and (min-width: 675px) {
    .form__input.form__input--set-width {
        max-width: 217px;
        width: 100%;
    }
}

.form__input--mobile-y-bottom {
    margin-bottom: 16px;
}

input.form__input.input-validate-highlight, select.form__dropdown.input-validate-highlight, input.input-validate-highlight,
    input:invalid, select:invalid {
    border-color: var(--colour__secondary);
    border-width: 1px;
    border-style: solid;
    outline: 0;
}

@media only screen and (min-width: 675px) {
    .form__input--mobile-y-bottom {
        margin-bottom: 0;
    }
}

.form__textarea {
    height: 100px;
    padding: 11px;
    resize: none;
    overflow-y: scroll;
}

.form__description {
    display: block;
    margin: -4px 0 18px !important;
}

@media only screen and (min-width: 675px) {
    .form__description {
        margin: -4px 0 18px 160px !important;
    }
}

.form__mock-dropdown {
    display: block;
    vertical-align: center;
    padding-top: 6px;
    width: 100%; height: 30px;
    position: relative;
}

.form__mock-dropdown-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.form__mock-dropdown-options {
    display: block;
    position: absolute;
    top: 100%; left: 0;
    border: 1px solid var(--colour__grey);
    max-height: 0;
    transition: var(--transistion);
    overflow: hidden;
    width: 100%;
}

@media only screen and (min-width: 675px) {
    .form__mock-dropdown-options {
        width: 100px;
    }
}

.form__mock-dropdown-option {
    display: block;
    padding: 8px 11px;
    background-color: var(--colour__grey--light);
    border-top: 1px solid var(--colour__grey);
    width: 100%;
}

.form__mock-dropdown-input:checked ~ .form__mock-dropdown-options {
    max-height: 300px;
}

.form__mock-dropdown-methods {
    display: block;
    position: absolute;
    top: 100%; left: 0;
    border: 1px solid var(--colour__grey);
    max-height: 0;
    transition: var(--transistion);
    overflow: hidden;
    width: 100%;
}

@media only screen and (min-width: 675px) {
    .form__mock-dropdown-methods {
        width: 280px;
    }
}

.form__mock-dropdown-method {
    display: flex;
    align-items: center;
    padding: 8px 11px;
    background-color: var(--colour__grey--light);
    width: 100%;
    border-top: 1px solid var(--colour__grey);
}

.form__mock-dropdown-input:checked ~ .form__mock-dropdown-methods {
    max-height: 300px;
}

.form__radio {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
}

.form__radio .form__radio-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.form__radio-custom {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: var(--colour__white);
    border-radius: 50%;
    margin-left: 10px;
}

.form__radioInput {
    display: flex;
    margin: 10px;
}

.form__radioInput label{
    margin-left: 10px;
    top: 5px;
    position: relative;
    top: 2px;
}

.form__radioInput input {
    width: 20px;
    height: 20px;
}


/* Custom Radio Button to use the colour scheme. */
input[type='radio']:after {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: var(--colour__grey);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: var(--colour__secondary);
    
    /* background-color: var(--colour__primary--dark); */
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}


.form__radio:hover .form__radio-input ~ .form__radio-custom {
    background-color: rgba(255, 255, 255, 0.8);
}

.form__radio .form__radio-input:checked ~ .form__radio-custom {
    background-color: var(--colour__white);
}

.form__radio .form__radio-input:checked ~ .form__radio-custom:after {
    display: block;
}

.form__radio .form__radio-custom:after {
    content: "";
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--colour__secondary);
}

.form__checkbox {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    margin-right: 5px;
}

/* Hide the browser's default checkbox */
.form__checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.form__checkbox-custom {
    position: absolute;
    top: 0;
    left: 5px;
    height: 20px;
    width: 20px;
    background-color: var(--colour__white);
}

/* When the checkbox is checked, add a blue background */
.form__checkbox .form__checkbox-input:checked ~ .form__checkbox-custom {
    background-color: var(--colour__white);
}

/* Create the checkmark/indicator (hidden when not checked) */
.form__checkbox-custom:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.form__checkbox .form__checkbox-input:checked ~ .form__checkbox-custom:after {
    display: block;
}

/* Style the checkmark/indicator */
.form__checkbox .form__checkbox-custom:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid var(--colour__secondary);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/**
 *  Content boxes
 * ------------------------------------------------------------------------- */
.content-boxes {
    padding: 16px 0 16px;
}

.content-boxes__box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    margin-bottom: 14px;
    background: var(--colour__grey);
    cursor: pointer;
}

@media only screen and (min-width: 675px) {
    .content-boxes__box {
        width: 100%;
        padding: 18px;
        flex-direction: row;
        align-items: flex-start;
        text-align: left;
    }
}

.content-boxes__box.content-boxes__box--max-494 {
    max-width: 494px;
    margin: 0 auto 14px;
}

.content-boxes__box-image-wrapper {
    width: 70px; height: 70px;
    background: var(--colour__white);
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 18px;
    flex-shrink: 0;
    position: relative;
}

@media only screen and (min-width: 675px) {
    .content-boxes__box-image-wrapper {
        margin-right: 28px;
    }
}

.content-boxes__box-svg-fill {
    fill: var(--colour__primary);
}

.content-boxes__box-content-title,
.content-boxes__box-content-text {
    display: block;
}

.content-boxes__box-content-title {
    margin-bottom: 6px !important;
    color: var(--colour__secondary) !important;
}

.content-boxes__box-content-text {
    margin-bottom: 0 !important;
    width: 100%;
}

.content-boxes__box-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.content-boxes__link  {
    color: var(--colour__secondary) !important;
    text-decoration: none !important;
}

.content-boxes__link:hover  {
    text-decoration: underline !important;
}

.content__app-store-link {
    margin: 0 7px 24px;
    display: inline-block;
}

.content-boxes__optionset {
    display: block;
    margin-top: 8px;
}

@media only screen and (min-width: 675px) {
    .content-boxes__optionset {
        display: inline;
    }
}


/**
/* Login Overlay
/* ------------------------------------------------------------------------- */

.loginOverlay{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.loginOverlay form{
    display: flex;
    flex-direction: column;
    margin: 20px 50px 20px;
    max-width: 220px;
}

.loginOverlay form label{
    color: var(--colour__font);
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    text-align: center;
    font-size: 14px;
    margin-bottom:6px;
}

.loginOverlay form input{
    height: 30px;
    font-size: 14px;
    color: var(--colour__font);
    background: var(--colour__grey);
    appearance: none;
    border: 0;
    width: 100%;
    padding-left: 11px;
    padding-right: 11px;
    /* border-radius: 20px; */
}

.loginOverlay form .wrapper {
    display: flex;
}

@media screen and (max-width: 420px) {
    .loginOverlay form{
        display: flex;
        flex-direction: column;
        margin: 0;
        max-width: 220px;
    }
}

.loginOverlay form .wrapper button{
    text-align: center;
    margin-top: 16px;
    margin-bottom: 30px;
    /* border-radius: 20px; */
}

.loginOverlay form .wrapper .cancelLink{
    display: none !important;
    font-size: 14px;
    text-align:center;
    margin-left: 0;
    width: 100%;
}

.loginOverlay form legend{
    max-width: 360px;
    line-height: 16px;
    font-size: 12px;
    color: var(--colour__font);
}

.loginOverlay p{
    max-width: 220px;
    line-height: 16px;
    margin-top: 20px;
    font-size: 12px;
    color: var(--colour__font);
}

/* DOB */
.form__label__date {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.form__label__date > legend {
    margin-left: 8px;
    font-weight: 500;
    width: 175px;
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}

@media only screen and (min-width: 675px) {
    .form__label__date {
        display: flex;
        width: 100%;
        flex-direction: row;
    }
    .form__label__date > legend {
        margin-left: 16px;
        margin-right: 12px;
        font-weight: 500;
        align-items: center;
        margin-bottom: none;
        margin-bottom: unset;
    }
}

/* Radio Button */

.form__label__radioButton > legend {
    /* margin-left: 8px;
    width: 100%; */
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-right: 12px;
}

.form__radio-wrapper.horizontal{
    display: flex;
    flex-direction: column;
}

@media only screen and (min-width: 675px) {
    .form__label__radioButton{
        margin-left: 16px;
        margin-right: 12px;
        margin-bottom: unset;
        display: flex;
    }
    
    .form__label__radioButton legend{
        width: 135px;
    }
}

/* File Upload*/
.form__label__fileUpload > legend{
    margin-left: 0px;
    margin-right: 0px;
    font-weight: 500;
    margin-bottom: 7px;
    width: 146px;
}

@media only screen and (min-width: 675px) {
    .form__label__fileUpload{
        display: flex;
    }
    .form__label__fileUpload > legend{
        margin-left: 16px;
        margin-right: 12px;
        font-weight: 500;
        margin-bottom: 7px;
    }
}

/* Back Button*/
button.backButton {
    padding: 0;
    font-size: 14px;
    background-color: transparent;
    color: var(--colour__w3c__primary) !important;
}

hr.backButton {
    margin: 10px 0px 30px 0px;
}

button.backButton:hover {
    color: var(--colour__font) !important;
}

/* Login and Password*/
#passwordChangeFields{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.passwordField{
    display:flex;
    flex-direction:column;
    width: 200px;
    font-size: 14px;
    margin-top: 14px;
    color: var(--colour__font);
}

.passwordField span{
    margin-bottom: 6px;
    text-align: center;
    font-weight: 500;
}

.passwordField input{
    background: var(--colour__grey);
    border: 0;
    height: 30px;
    padding-left: 11px;
    padding-right: 11px;
}

.changePasswordButton{
    margin-top: 30px;
    width: 200px;
}

.changePasswordButton:disabled{
    background-color:var(--colour__grey--dark);
    cursor: not-allowed;
}

.changePasswordErrorMessage{
    color: var(--colour__secondary--dark);
    font-size: 14px;
    width: 200px;
    line-height: 16px;
    margin-top: 12px;
}

#passwordChangeSuccessMessage{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CheckImageForPasswordChange{
    width: 100px;
}

.passwordChangeSuccessMessageText{
    font-size: 15px;
    margin-top: 20px;
}

.passwordChangeSuccessMessageButton{
    margin-top: 20px;
}

.option {
    border:1px solid grey;
    border-radius: 8px;
    padding:10px;
    margin-bottom:20px;
}